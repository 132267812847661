import CategoryIcon from '@mui/icons-material/Category';
import CategoryOutlined from '@mui/icons-material/CategoryOutlined';
import QrCodeIcon from '@mui/icons-material/QrCode';
import StoreIcon from '@mui/icons-material/Store';
import HttpIcon from '@mui/icons-material/Http';
import GroupIcon from '@mui/icons-material/Group';
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import NotificationsIcon from '@mui/icons-material/Notifications';

type NavigationItem = {
  title: string;
  path: string;
  icon: JSX.Element;
};

const navigation: NavigationItem[] = [
  {
    title: 'Users',
    path: '/',
    icon: <GroupIcon />,
  },

  {
    title: 'Technologies',
    path: '/categories',
    icon: <CategoryIcon />,
  },
  {
    title: 'Subtechnologies',
    path: '/subcategories',
    icon: <CategoryOutlined />,
  },
  {
    title: 'Resources',
    path: '/resources',
    icon: <QrCodeIcon />,
  },

  {
    title: 'Companies',
    path: '/companies',
    icon: <StoreIcon />,
  },

  {
    title: 'Websites',
    path: '/websites',
    icon: <HttpIcon />,
  },
  {
    title: 'Types',
    path: '/types',
    icon: <TypeSpecimenIcon />,
  },

  {
    title: 'Notifications',
    path: '/notifications',
    icon: <NotificationsIcon />,
  },

  {
    title: 'Users History',
    path: '/users-history',
    icon: <GroupIcon />,
  },
];

export default navigation;
