import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Paper, Typography } from '@mui/material';
import { ResourcesItemType } from '@/shared/api/resources/resources.types';
import ResourcesApi from '@/shared/api/resources/resources.api';
import CompanniesApi from '@/shared/api/compnaies/compnaies.api';
import NotificationApi from '../../api/notification.api';
import { TNotificationItem } from '../../types';
import { Tid } from '@/shared/types/promises.types';
import { CompanyItemType } from '@/shared/api/compnaies/compnaies.api.types';

const NotificationContainer = () => {
  const { id } = useParams<{ id: string }>();

  const [notification, setNorification] = useState<TNotificationItem | null>(
    null,
  );

  const [resource, setResource] = useState<ResourcesItemType | null>(null);
  const [company, setCompany] = useState<CompanyItemType | null>(null);

  const setViewedNotification = async () => {
    await NotificationApi.setViewedNotification(id || '12', {
      viewed: true,
    });
  };

  const fetchResourceById = async (id: Tid) => {
    const { data } = await ResourcesApi.fetchResourceById(id);

    setResource(data);
  };

  const fetchCompanyById = async (id: Tid) => {
    const { data } = await CompanniesApi.fetchCompanyById(id);

    setCompany(data);
  };

  const fetchNotification = async () => {
    const { data } = await NotificationApi.getNotificationById(id || '12');

    if (data.meta_data?.resource_id) {
      await fetchResourceById(data.meta_data?.resource_id);
    }
    if (data.meta_data?.company_id) {
      await fetchCompanyById(data.meta_data?.company_id);
    }

    setNorification(data);
  };

  useEffect(() => {
    fetchNotification();
    setViewedNotification();
  }, [id]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          INKHUB Admin Pange | {notification?.title || 'Notification'}
        </title>
      </Helmet>

      <Box>
        <Link
          to={{
            pathname: '/notifications',
          }}
        >
          <Typography variant="caption" marginRight={2}>
            Back to notifications
          </Typography>
        </Link>
        <Box display="flex" alignItems="center" marginBottom={10} marginTop={4}>
          <Typography variant="h1" marginRight={2}>
            {notification?.title}
          </Typography>
        </Box>

        <Box display="flex" alignItems="flex-start">
          <Paper
            sx={{
              display: 'inline-block',
              marginRight: 2,
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5" padding={2}>
                Name:
              </Typography>
              <Typography variant="body1" padding={2} fontWeight={800}>
                {notification?.meta_data?.name ||
                  `${notification?.meta_data?.first_name} ${notification?.meta_data?.last_name}`}
              </Typography>
            </Box>

            {notification?.meta_data?.user_role && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" padding={2}>
                  Role:
                </Typography>
                <Typography variant="body1" padding={2} fontWeight={800}>
                  {notification?.meta_data?.user_role}
                </Typography>
              </Box>
            )}

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5" padding={2}>
                Email:
              </Typography>
              <Typography variant="body1" padding={2} fontWeight={800}>
                {notification?.meta_data?.email}
              </Typography>
            </Box>
          </Paper>
          {notification?.title !== 'New Opt-Out Request' && (
            <>
              {company ? (
                <Paper
                  sx={{
                    padding: 2,
                  }}
                >
                  <a
                    href={`https://dev.inkhub.com/company/${company.id}`}
                    target="_blank"
                  >
                    <Typography variant="body1" fontWeight={800} mb={2}>
                      {company?.name}
                    </Typography>

                    <img src={company?.image_path} width={250} />
                  </a>
                </Paper>
              ) : (
                <Paper
                  sx={{
                    padding: 2,
                  }}
                >
                  <a
                    href={`https://dev.inkhub.com/resource/${resource?.id}`}
                    target="_blank"
                  >
                    <Typography variant="body1" fontWeight={800} mb={2}>
                      {resource?.name}
                    </Typography>

                    <img src={resource?.image_path} width={250} />
                  </a>
                </Paper>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
export default NotificationContainer;
